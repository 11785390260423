<template>
  <div class="LAccordionParent">
    <div
      v-if="alertMessage"
      class="alert alert-danger"
      role="alert"
      style="margin: 0 0 1rem 0"
    >
      <i class="fas fa-exclamation-triangle" style="margin-right: 0.5rem"></i>
      <span v-html="alertMessage.msg"></span>
      <template v-if="alertMessage.pre">
        <span>
          cu
          <b
            ><a
              href="#"
              @click.prevent="alertPre = !alertPre"
              style="color: inherit; font-style: italic"
              >motivul
              <i
                :class="`fas fa-${alertPre ? 'minus' : 'plus'}-circle`"
              ></i></a></b
        ></span>
        <pre
          v-if="alertPre"
          style="
            text-align: left;
            margin: 0.5rem 0 0;
            padding: 1rem;
            max-height: 10rem;
          "
          >{{ alertMessage.pre }}</pre
        >
      </template>
    </div>
    <div style="text-align: left; padding-left: 2rem; margin-bottom: 1rem">
      <span v-if="Info.needType" style="margin-right: 1rem;">
        <span v-if="needType == 'complex'" style="color: #8a6d3b;">
          <img
            src="../assets/media/for_table/complex.svg"
            style="width: 1.75rem;margin-top: -0.4rem;"
            alt=""
          />
          Nevoie conexată complexă
        </span>
        <span v-else-if="needType == 'similar'" style="color: #286090;">
          <i class="fas fa-check-double"></i>
          Nevoie conexată similară
        </span>
      </span>
      <span v-if="Info.socialType">
        <span v-if="Info.socialType === 1" style="color: green">
          <i class="fas fa-circle"></i>
          Nevoie {{ isConexed ? "dominantă" : "" }} Socială
        </span>
        <span v-if="Info.socialType === 2" style="color: #8a6d3b">
          <i class="fas fa-circle"></i>
          Nevoie {{ isConexed ? "dominantă" : "" }} Non-socială
        </span>
      </span>
    </div>
    <div class="LAccordionItem" :class="selected[0] ? 'LActive' : ''">
      <div @click="selectLabel(0)" class="LAccordionItemHead">
        <span>Informații propunere de nevoie</span>
        <div>
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC1']">
        <div ref="AC1">
          <h4
            v-if="!Info.conexed.length && isConexed"
            class="prettyH4"
            style="color: #888"
          >
            <i class="fas fa-times"></i>
            Nu sunt produse
          </h4>
          <div v-else class="TBParent">
            <table>
              <thead>
                <th>Denumire</th>
                <th>Unitate de măsură</th>
                <th>Cantitate</th>
                <th>Valoare estim./u.m. (fară TVA)</th>
                <th>Valoare estim./u.m. (cu TVA)</th>
                <th>Valoare estimată totală (fară TVA)</th>
                <th>Valoare estimată totală</th>
              </thead>
              <tbody>
                <tr v-if="!isConexed">
                  <td>{{ Info.product.name }}</td>
                  <td>
                    <span>{{ Info.product.mu.name }}</span>
                    <i
                      v-tooltip="Info.product.mu.desc"
                      class="fas fa-info-circle"
                      style="margin-left: 0.5rem"
                    ></i>
                  </td>
                  <td>{{ Info.product.qty }}</td>
                  <td>{{ Info.product.espOutTva }}</td>
                  <td>{{ Info.product.esp }}</td>
                  <td>{{ Info.product.esptOutTva }}</td>
                  <td>{{ Info.product.espt }}</td>
                </tr>
                <template v-if="isConexed">
                  <tr v-for="e in Info.conexed" :key="e._Id">
                    <td>{{ e.product.name }}</td>
                    <td>
                      <span>{{ e.product.mu.name }}</span>
                      <i
                        v-tooltip="e.product.mu.desc"
                        class="fas fa-info-circle"
                        style="margin-left: 0.5rem"
                      ></i>
                    </td>
                    <td>{{ e.product.qty }}</td>
                    <td>{{ e.product.espOutTva }}</td>
                    <td>{{ e.product.esp }}</td>
                    <td>{{ e.product.esptOutTva }}</td>
                    <td>{{ e.product.espt }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="LAccordionItem" :class="selected[8] ? 'LActive' : ''">
      <div @click="selectLabel(8)" class="LAccordionItemHead">
        <span>Informații categorii de achiziții</span>
        <div>
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC9']">
        <div ref="AC9">
          <h4 v-if="!Info.dominant" class="prettyH4" style="color: #888">
            <i class="fas fa-times"></i>
            Nu sunt categorii de achiziții
          </h4>
          <div v-else class="TBParent">
            <table>
              <thead>
                <th>CPV</th>
                <th>Denumire</th>
              </thead>
              <tbody>
                <tr>
                  <td>{{ Info.dominant.product.cpvCategory.cpvCode }}</td>
                  <td>{{ Info.dominant.product.cpvCategory.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="LAccordionItem" :class="selected[1] ? 'LActive' : ''">
      <div @click="selectLabel(1)" class="LAccordionItemHead">
        <span>Informații Creare</span>
        <div>
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC2']">
        <div ref="AC2">
          <div class="TBParent">
            <table>
              <thead>
                <th>Data/Ora creării</th>
                <th>Nume Prenume Solicitant / ID ConectX</th>
                <th>Rol Solicitant</th>
                <th>Denumire instituție</th>
                <th v-if="Info.creation.dep">Structură organizatorică</th>
              </thead>
              <tbody>
                <tr>
                  <td>{{ Info.creation.date }}</td>
                  <td>{{ Info.creation.applicant }}</td>
                  <td>{{ Info.creation.applicantRole }}</td>
                  <td>{{ Info.creation.inst }}</td>
                  <td v-if="Info.creation.dep">{{ Info.creation.dep }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="Info.modification.show"
      class="LAccordionItem"
      :class="selected[2] ? 'LActive' : ''"
    >
      <div @click="selectLabel(2)" class="LAccordionItemHead">
        <span>Informații Modificare</span>
        <div>
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC3']">
        <div ref="AC3">
          <div class="TBParent">
            <table>
              <thead>
                <th>Data/Ora modificării</th>
                <th>Modificat de către</th>
                <th>Rol Solicitant</th>
                <th>Denumire instituție</th>
                <th>Structură organizatorică</th>
              </thead>
              <tbody>
                <tr>
                  <td>{{ Info.modification.date }}</td>
                  <td>{{ Info.modification.modifier }}</td>
                  <td>{{ changeRoleView(Info.modification.modifierRole) }}</td>
                  <td>{{ Info.modification.inst }}</td>
                  <td>{{ Info.modification.dep }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="Info.approver.show"
      class="LAccordionItem"
      :class="selected[3] ? 'LActive' : ''"
    >
      <div @click="selectLabel(3)" class="LAccordionItemHead">
        <span
          >Informații Aprobare{{ Info.avizo.show ? " & Avizare" : "" }}</span
        >
        <div>
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC4']">
        <div>
          <div ref="AC4">
            <div class="TBParent">
              <table>
                <thead>
                  <th>Data/Ora aprobării</th>
                  <th>Detalii Aprobator</th>
                  <th>Instituție</th>
                  <th>Structură organizatorică aprobator</th>
                  <th>Structură organizatorică propunere</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ Info.approver.date }}</td>
                    <td>{{ Info.approver.userDetails }}</td>
                    <td>{{ Info.approver.institution }}</td>
                    <td>{{ Info.approver.approvalDep }}</td>
                    <td>{{ Info.approver.proposalDep }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <template v-if="Info.approver.observation">
              <div class="hr1" style="margin: 1rem 0"></div>
              <b
                >Observații:
                <i
                  @click="
                    Info.approver.showObservation = !Info.approver
                      .showObservation
                  "
                  :class="
                    `clickableI fas fa-${
                      Info.approver.showObservation ? 'minus' : 'plus'
                    }-circle`
                  "
                ></i
              ></b>
              <pre
                v-if="Info.approver.showObservation"
                style="margin: 0; white-space: pre-wrap"
                >{{ Info.approver.observation }}</pre
              >
            </template>
            <template v-if="Info.avizo.show">
              <div
                v-if="Info.approver.observation"
                class="hr1"
                style="margin: 1rem 0"
              ></div>
              <aside
                align="left"
                style="margin: 1.25rem 0 0.5rem 17px"
                class="arrow"
              >
                Tipul de flux:
                <span v-if="Info.avizo.mode">{{ Info.avizo.mode }}</span
                ><i
                  v-else
                  class="fas fa-sync fa-spin"
                  style="font-size: 2.2rem"
                ></i>
              </aside>
              <div class="hr1" style="margin-bottom: 0.5rem"></div>
              <div class="TBParent" align="center">
                <i
                  v-if="!Info.avizo.data.length"
                  class="fas fa-sync fa-spin"
                  style="font-size: 5.5rem; margin: 2rem"
                ></i>
                <table v-else>
                  <thead>
                    <th>Aviz</th>
                    <th>Nume, Prenume</th>
                    <th>Id ConectX</th>
                    <th>Rolul</th>
                    <th>Structură organizatorică</th>
                  </thead>
                  <tbody>
                    <tr v-if="Info.avizo.data[0] === false">
                      <td colspan="4" style="text-align: center; padding: 2rem">
                        <h3 style="margin: 0; font-weight: bold; color: #888">
                          <i
                            style="pointer-events: none"
                            class="fas fa-exclamation-triangle"
                          ></i>
                          Nu s-a gasit nimic
                        </h3>
                      </td>
                    </tr>
                    <template v-else v-for="(e, i) in Info.avizo.data">
                      <tr>
                        <td>
                          <span v-html="e.action"></span>
                          <i
                            v-if="e.reason"
                            @click="
                              Info.avizo.reasonIndex =
                                Info.avizo.reasonIndex === i ? -1 : i
                            "
                            :class="
                              `clickableI fas fa-${
                                Info.avizo.reasonIndex === i ? 'minus' : 'plus'
                              }-circle`
                            "
                            style="margin-left: 0.5rem"
                          ></i>
                        </td>
                        <td>
                          {{ e.name || "-" }}
                        </td>
                        <td>
                          {{ e.userId || "-" }}
                        </td>
                        <td>
                          {{ changeRoleView(e.avizatorDetails.role) || "-" }}
                        </td>
                        <td>
                          {{
                            { null: "-", "nivel institutie": " " }[
                              e.avizatorDetails.department
                            ] ||
                              e.avizatorDetails.department ||
                              "-"
                          }}
                        </td>
                      </tr>
                      <tr v-if="Info.avizo.reasonIndex === i" :key="i + 0.5">
                        <td
                          colspan="5"
                          style="background-color: #fff !important"
                        >
                          <pre style="margin: 0; white-space: pre-wrap">{{
                            Info.avizo.data[Info.avizo.reasonIndex].reason
                          }}</pre>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="hr1" style="margin: 1rem 0 0.5rem"></div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="LAccordionItem" :class="selected[5] ? 'LActive' : ''">
      <div @click="selectLabel(5)" class="LAccordionItemHead">
        <span
          >Descriere tehnica -
          {{
            Info.dominant.modalitateaUtilizataDescriereaCaracteristicilor
          }}</span
        >
        <div>
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC6']">
        <div ref="AC6" class="noMarginParagraph">
          <div v-if="Info.dominant.caietulDeSarciniTrimiteri">
            <b
              >Caietul de sarcini va include trimiteri la următoarele categorii
              de standarde (ordinea de prioritate a standardelor astfel cum este
              stabilită la art. 156 alin.(1), lit b) din Legea 98/2016):</b
            >
            <ul>
              <li v-for="k in Info.dominant.caietulDeSarciniTrimiteri">
                {{ k }}
              </li>
            </ul>
          </div>

          <div
            v-if="!isConexed"
            style="max-height: 45rem; margin: 0"
            v-html="Info.tehnicalDesc.val"
          ></div>
          <h4
            v-else-if="!Info.conexed.length"
            class="prettyH4"
            style="color: #888"
          >
            <i class="fas fa-times"></i>
            Nu sunt descrieri tehnice
          </h4>
          <pre
            v-else
            v-for="e in Info.conexed"
            :key="e._Id"
            v-html="e.technicalDescription"
            style="max-height: 15rem"
          ></pre>
        </div>
      </div>
    </div>
    <div
      v-if="Info.rejection.show"
      class="LAccordionItem"
      :class="selected[7] ? 'LActive' : ''"
    >
      <div @click="selectLabel(7)" class="LAccordionItemHead">
        <span>Motivul respingerii</span>
        <div>
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC8']">
        <div ref="AC8">
          <pre
            vif="Info.rejection.reason"
            style="max-height: 45rem; margin: 0"
            >{{ Info.rejection.reason }}</pre
          >
          <h4 style="margin: 0; color: #a94442; cursor: default">
            <i class="fas fa-times-circle"></i> Nici un motiv indicat
          </h4>
        </div>
      </div>
    </div>
    <div class="LAccordionItem" :class="selected[6] ? 'LActive' : ''">
      <div @click="selectLabel(6)" class="LAccordionItemHead">
        <span
          >Fișiere atașate<span id="WDQDGHjdhqwdjhjhqwddiqwjiowqjd"
            ><i @click="viewFiles()" class="fas fa-eye"></i></span
        ></span>
        <div>
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC7']">
        <div ref="AC7" class="TBParent">
          <table
            v-if="
              (!isConexed && Info.docs.val.length) ||
                (isConexed && Info.conexed.length)
            "
          >
            <thead>
              <th>Denumire</th>
              <th>Acțiuni</th>
            </thead>
            <tbody>
              <template v-if="!isConexed">
                <tr v-for="(file, i) in Info.docs.val" :key="file[2]">
                  <td>{{ getNeedFileName(file[0]) }}</td>
                  <td>
                    <a href="#" @click.prevent="viewFiles(i)"
                      ><i class="fas fa-eye"></i> Deschide</a
                    >
                    |
                    <a
                      href="#"
                      @click.prevent="downloadFile(i)"
                      :style="
                        file[1] ? 'pointer-events: none;color: #666;' : ''
                      "
                      ><i class="fas fa-download"></i> Descarcă</a
                    >
                    <span
                      v-if="file[1]"
                      style="display: inline-block; transform: translateY(2px)"
                    >
                      <i
                        class="fas fa-sync fa-spin"
                        style="
                          font-size: 2rem;
                          pointer-events: none;
                          margin-left: 0.5rem;
                        "
                      ></i>
                    </span>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(file, i) in conexedFiles" :key="file[3]">
                  <td>{{ getNeedFileName(file[0]) }}</td>
                  <td>
                    <a href="#" @click.prevent="viewFiles(i)"
                      ><i class="fas fa-eye"></i> Deschide</a
                    >
                    |
                    <a
                      href="#"
                      @click.prevent="downloadFile(i)"
                      :style="
                        file[1] ? 'pointer-events: none;color: #666;' : ''
                      "
                      ><i class="fas fa-download"></i> Descarcă</a
                    >
                    <span
                      v-if="file[1]"
                      style="display: inline-block; transform: translateY(2px)"
                    >
                      <i
                        class="fas fa-sync fa-spin"
                        style="
                          font-size: 2rem;
                          pointer-events: none;
                          margin-left: 0.5rem;
                        "
                      ></i>
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <h4 v-else style="margin: 0; color: #a94442; cursor: default">
            <i class="fas fa-times-circle"></i> Nu sunt fișiere atașate
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NEEDS_DOWNLOAD_FILE, NEEDS_FROM_CONEXED } from "@/api.js";
import { mapMutations } from "vuex";
import LAcordion from "@/mixins/LAcordion.js";

const saver = require("file-saver");
const defaultInfo = () => ({
  product: {
    name: "",
    mu: {
      name: "",
      desc: "",
    },
    qty: "",
    esp: "",
    espOutTva: "",
    espt: "",
    esptOutTva: "",
    __id: 0,
    __selected: false,
  },
  achizition: {
    data: [],
    __id: 8,
    __selected: false,
  },
  creation: {
    date: "",
    applicant: "",
    applicantRole: "",
    inst: "",
    dep: "",
    __id: 1,
    __selected: false,
  },
  modification: {
    show: false,
    date: "",
    modifier: "",
    modifierRole: "",
    inst: "",
    dep: "",
    __id: 2,
    __selected: false,
  },
  approver: {
    show: false,
    // name: "",
    // id: "",
    // role: "",
    // inst: "",
    // dep: "",
    date: "",
    userDetails: "",
    institution: "",
    approvalDep: "",
    proposalDep: "",
    observation: "",
    showObservation: false,
    __id: 3,
    __selected: false,
  },
  avizo: {
    show: false,
    mode: false,
    data: [],
    reasonIndex: -1,
    __id: 4,
    __selected: false,
  },
  rejection: {
    show: false,
    reason: "",
    __id: 7,
    __selected: false,
  },
  tehnicalDesc: {
    val: "",
    __id: 5,
    __selected: false,
  },
  docs: {
    val: [],
    __id: 6,
    __selected: false,
  },
  socialType: false,
  needType: false,
  conexed: [],
  dominant: null,
  needApprover: false,
});
function findLastIndex(array, searchKey, searchValue) {
  const index = array
    .slice()
    .reverse()
    .findIndex((x) => x[searchKey] === searchValue);
  const count = array.length - 1;
  const finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
}

export default {
  mixins: [LAcordion],
  props: {
    Data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    const needType = this.Data?.needData?.needType;
    const findConexed = ["complex", "similar"].find((e) => e === needType);

    return {
      Info: defaultInfo(),
      alertMessage: null,
      alertPre: false,
      cachedFiles: {},
      isConexed: !!findConexed,
      needType,
      prepareAcquisitionData(data) {
        if (!this.isObject(data)) return null;

        const prepare = {
          _Id: this.makeid(6),
          id: data.id,
        };

        prepare.name = data.achizitionCategory || "-";

        this.walker(
          prepare,
          ["budgetClasification", "name"],
          data.budgetClasification?.name || "-",
          true
        );

        return prepare;
      },
    };
  },
  computed: {
    selected() {
      const prepare = {};
      Object.values(this.Info).forEach((e) => {
        prepare[e?.__id] = e?.__selected;
      });
      return prepare;
    },
    conexedFiles() {
      if (!this.isConexed) return [];

      return this.Info.conexed.flatMap((e) =>
        e.docs.val.map((q) => [...q, e.id, this.makeid(6)])
      );
    },
  },
  methods: {
    ...mapMutations(["addWindow"]),

    selectLabel(index) {
      if (!Number.isInteger(index)) return;
      const x = Object.entries(this.Info).find((e) => e[1].__id === index);
      if (Array.isArray(x)) {
        x[1].__selected = !x[1].__selected;
      }
    },
    getNeedId() {
      const x = this.Data.needData?.id;
      return Number.isInteger(this.Data.needData?.id)
        ? this.Data.needData?.id
        : NaN;
    },
    viewFiles(index = NaN) {
      this.addWindow(
        Object.assign(
          {
            fileList: this.isConexed
              ? this.conexedFiles.map((e) => e[0])
              : this.Info.docs.val.map((e) => e[0]),
            canDelete: false,
            modal: 13,
          },
          this.isConexed
            ? {
                itemId: (() => {
                  const prepare = {};
                  this.conexedFiles.forEach((e) => (prepare[e[0]] = e[2]));
                  return prepare;
                })(),
              }
            : { itemId: this.getNeedId() },
          !isNaN(index) ? { select: index } : {}
        )
      );
    },
    downloadFile(index) {
      if (!Number.isInteger(index)) return;
      const t = this.isConexed;
      const findFile = t ? this.conexedFiles[index] : this.Info.docs.val[index];
      if (!findFile || findFile[1]) return;
      if (this.cachedFiles.hasOwnProperty(findFile[0])) {
        saver.saveAs(this.cachedFiles[findFile[0]], findFile[0]);
        return;
      }
      findFile.splice(1, 1, true);
      NEEDS_DOWNLOAD_FILE(t ? findFile[2] : this.getNeedId(), findFile[0])
        .then((file) => {
          findFile.splice(1, 1, false);
          if (!file || !file.type || file.type == "application/json") {
            this.$toastr.Add({
              msg: `Au apărut errori la descărcarea fișierului: ${findFile[0]}`,
              timeout: 10000,
              type: "error",
            });
            return;
          }
          saver.saveAs(file, findFile[0]);
          this.cachedFiles[findFile[0]] = file;
        })
        .catch((err) => {
          findFile.splice(1, 1, false);
          this.$toastr.e(err);
        });
    },
    initData() {
      const x = this.Data;
      const y = x.needData || {};
      const q = x.additionalData || {};
      const z = this.Info;

      let doNotShowObservation = false;

      // needType
      if (this.isConexed && this.needType) {
        z.needType = true;
      }

      // socialType
      z.socialType =
        typeof y.needIsSocial == "boolean" ? (y.needIsSocial ? 1 : 2) : null;

      // alertMessage
      if (this.isObject(y.data)) {
        if (y.data.productChanges) {
          this.alertMessage = {
            msg: `Această propunere de nevoie a fost anulată automat în urma modificării poziției de catalog la data de <b>${this.toDate(
              q.createdAt
            )}</b>.`,
          };
        } else {
          doNotShowObservation = true;

          const msg = `Această propunere de nevoie a fost anulată la data de <b>${this.toDate(
            q.createdAt
          )}</b> de către <b>${this.readUserObject(q.createdByUser)}</b>`;

          const reason = y.data.changes?.observation;

          this.alertMessage = Object.assign(
            {
              msg,
            },
            reason ? { pre: reason } : null
          );
        }
      }

      z.needApprover = !y.approver && y.needType == "open";
      z.approver.show = !!y.approver;

      // product
      if (y.product) {
        z.product.name = y.product.name;

        if (y.product.measurementUnit) {
          z.product.mu.name = y.product.measurementUnit.shortName;
          z.product.mu.desc = y.product.measurementUnit.description;
        }
      }
      const espt = this._Multiply(y.estimatedPrice, y.qty);
      z.product.qty = Number.isInteger(y.qty) ? y.qty : "";
      z.product.esp = y.estimatedPrice
        ? this.localeReplacePoints(y.estimatedPrice)
        : "";
      z.product.espt = isNaN(espt) ? "" : this.prepareLocaleNumber(espt);

      z.product.espOutTva = y.estimatedPriceWoTva
        ? this.localeReplacePoints(y.estimatedPriceWoTva)
        : "";
      z.product.esptOutTva = isNaN(this._Multiply(y.estimatedPriceWoTva, y.qty))
        ? ""
        : this.prepareLocaleNumber(
            this._Multiply(y.estimatedPriceWoTva, y.qty)
          );
      z.dominant = y;
      // achizition
      const prepareAcq = this.prepareAcquisitionData(y.achizitionCategory);
      if (prepareAcq) {
        z.achizition.data.push(prepareAcq);
      }

      // creation
      z.creation.date = y.createdAt ? window.$toDateAndTime(y.createdAt) : "";
      if (y.createdByUser) {
        z.creation.applicant = `${y.createdByUser.last_name} ${y.createdByUser.first_name} / ${y.createdByUser.id}`;
      }
      z.creation.inst = y.institution?.name || "";
      z.creation.dep = y.department?.name || "";
      z.creation.applicantRole = this.changeRoleView(y.createdByUserRoleName);

      // modification
      z.modification.show = q.versionNumber !== 0 && y.status != "approved";
      z.modification.date = q.createdAt
        ? window.$toDateAndTime(q.createdAt)
        : "";

      if (q.createdByUser) {
        z.modification.modifier = `${q.createdByUser.last_name} ${q.createdByUser.first_name} / ${q.createdByUser.id}`;
      } else if (q.createdBy) {
        z.modification.modifier = q.createdBy.replace("(ID)=", "");
      }
      z.modification.modifierRole = q.createdByUserRoleName || "";
      z.modification.inst = y.institution?.name || "";
      z.modification.dep = y.department?.name || "";

      // approver
      if (z.approver.show) {
        if (Array.isArray(y.approvers) && y.approvers[0]) {
          const w =
            (this.JSONvalide(y.approvers[0])
              ? JSON.parse(y.approvers[0])[0]
              : y.approvers[0]) || "";
          if (this.isString(w)) {
            const y = w.split("*")[2];
            const role = w.split("—")[2]?.trim() || w.split("—")[1]?.trim();
            const userName = y.split("/")[0] || "-";
            const userId = ((id) => (isNaN(id) ? "-" : id))(
              parseInt(y.split("/")[1])
            );

            z.approver.date =
              q.versionNumber > 0 ? this.toDateAndTime(q.createdAt) : "";
            z.approver.userDetails = `${userName} / ${userId} - ${role}`;
            z.approver.approvalDep = [
              "Șef instituție",
              "Admin instituție",
            ].includes(role)
              ? ""
              : w.split("—")[1] || "";
            z.approver.proposalDep = z.creation.dep;
            z.approver.role = role;
          }
        }
        z.approver.institution = y.institution?.name || "";
        if (!doNotShowObservation) z.approver.observation = y.observation || "";
      }

      // avizo
      const avizoActionsMap = {
        approved: "Aviz pozitiv",
        rejected: "Aviz negativ",
      };
      const avizatorii = Array.isArray(y.avizo?.avizatorii)
        ? y.avizo.avizatorii
        : [];
      z.avizo.show = avizatorii.length;
      z.avizo.mode = y.avizo?.avizoMode || "";
      const currentAvizoIndex =
        z.avizo.mode != "Facultativ"
          ? avizatorii.findIndex((e) => e.action == "undefined")
          : -1;
      let rejectIndex =
        z.avizo.mode == "Strict"
          ? findLastIndex(avizatorii, "action", "rejected")
          : -1;
      rejectIndex = rejectIndex === -1 ? NaN : rejectIndex;
      const cannotWaitAvizo =
        ["approved", "rejected"].includes(x.status) ||
        x.all?.slice(-1)[0].id !== q.versionNumber;
      z.avizo.data = avizatorii
        .map((e, i) =>
          Number.isInteger(e.avizatorDetails?.id)
            ? {
                ...e,
                name: `${e.avizatorDetails.last_name} ${e.avizatorDetails.first_name}`,
                userId: e.avizatorDetails.id,
                curr: i === currentAvizoIndex,
                showMore: false,
                action:
                  i > rejectIndex
                    ? ""
                    : avizatorii[i].action != "undefined"
                    ? avizoActionsMap[avizatorii[i].action] ||
                      avizatorii[i].action
                    : cannotWaitAvizo
                    ? ""
                    : currentAvizoIndex > -1 && i !== currentAvizoIndex
                    ? "În așteptare"
                    : "<i>Urmează să avizeze</i>",
                reason:
                  avizatorii[i].reason &&
                  avizatorii[i].reason != "No reason provided"
                    ? avizatorii[i].reason.trim()
                    : false,
              }
            : false
        )
        .filter((e) => e);

      // tehnicalDesc
      z.tehnicalDesc.val = y.technicalDescription || "";

      // docs
      z.docs.val =
        y.attachedDocuments?.map((e) => [e, false, this.makeid(6)]) || [];

      // rejection
      z.rejection.show = !!y.reject;
      z.rejection.reason = y.reject?.reason || "";
      if (z.rejection.reason == "Rejected by it's originator")
        z.rejection.reason = "";
    },
    syncConexed() {
      if (this.isConexed) {
        const err = (msg) => {
          this.$toastr.e(msg || "Încercați mai târziu");
          this.close();
          this.setLoad();
        };

        this.setSafeLoad();
        NEEDS_FROM_CONEXED(this.Data.needData.id)
          .then((res) => {
            if (Array.isArray(res?.data?.result)) {
              res = res.data.result;

              this.Info.conexed = res.map((y) => {
                if (!this.isObject(y)) return {};

                const prepare = {
                  _Id: this.makeid(6),
                  id: y.id,
                };

                // product
                if (y.product) {
                  this.walker(
                    prepare,
                    ["product", "name"],
                    y.product.name,
                    true
                  );

                  if (y.product.measurementUnit) {
                    this.walker(
                      prepare,
                      ["product", "mu", "name"],
                      y.product.measurementUnit.shortName,
                      true
                    );
                    this.walker(
                      prepare,
                      ["product", "mu", "desc"],
                      y.product.measurementUnit.description,
                      true
                    );
                  }
                }
                const espt = this._Multiply(y.estimatedPrice, y.qty);
                this.walker(
                  prepare,
                  ["product", "qty"],
                  Number.isInteger(y.qty) ? y.qty : "",
                  true
                );

                this.walker(
                  prepare,
                  ["product", "espOutTva"],
                  y.estimatedPriceWoTva
                    ? this.localeReplacePoints(y.estimatedPriceWoTva)
                    : "",
                  true
                );

                this.walker(
                  prepare,
                  ["product", "esp"],
                  y.estimatedPrice
                    ? this.localeReplacePoints(y.estimatedPrice)
                    : "",
                  true
                );

                this.walker(
                  prepare,
                  ["product", "esptOutTva"],
                  isNaN(this._Multiply(y.estimatedPriceWoTva, y.qty))
                    ? ""
                    : this.prepareLocaleNumber(
                        this._Multiply(y.estimatedPriceWoTva, y.qty)
                      ),
                  true
                );

                this.walker(
                  prepare,
                  ["product", "espt"],
                  isNaN(espt) ? "" : this.prepareLocaleNumber(espt),
                  true
                );

                // achizition
                const prepareAcq = this.prepareAcquisitionData(
                  y.achizitionCategory
                );
                if (
                  prepareAcq &&
                  !this.Info.achizition.data.find((e) => e.id === prepareAcq.id)
                ) {
                  this.Info.achizition.data.push(prepareAcq);
                }

                // Technical Description
                prepare.technicalDescription = y.technicalDescription;

                // Docs
                this.walker(
                  prepare,
                  ["docs", "val"],
                  y.attachedDocuments?.map((e) => [e, false]),
                  true
                );
                return prepare;
              });
              this.Info.dominant = res.find(
                (el) => (el.id = this.Data.needData.dominantNeed.id)
              );

              this.setLoad();
            } else {
              err();
            }
          })
          .catch(err);
      }
    },
    close() {
      this.$emit("Close");
    },
  },
  created() {
    this.initData();
    this.syncConexed();
  },
};
</script>

<style src="@/assets/LAcordion.css"></style>
<style lang="scss" scoped>
#WDQDGHjdhqwdjhjhqwddiqwjiowqjd {
  position: relative;
  cursor: default !important;
  border-radius: 50%;
  padding: 0.5rem;
  display: inline-flex;
  justify-content: center;
  z-index: 10;

  > i {
    transition: transform 200ms;
  }
  &:hover > i {
    transform: rotate(180deg);
  }
}
aside {
  position: relative;
  margin: 20px 0;
  line-height: 35px;
  position: relative;
  user-select: none;
  margin-left: 17px;
  padding-left: 1rem;
  font-weight: 600;
  color: #306565;
  font-size: 2rem;
  display: inline-block;
  padding-right: 1.5rem;
}
aside.arrow {
  background-color: rgba(51, 153, 255, 0.25);
}
aside.arrow:after,
aside.arrow:before {
  content: "";
  border-width: 17px 0 17px 17px;
}
aside.arrow:before {
  border-color: rgba(51, 153, 255, 0.25) transparent;
  left: -17px;
}
aside.arrow:after {
  border-color: transparent rgba(51, 153, 255, 0.25);
  right: -17px;
}
aside:before,
aside:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 0;
}
</style>
